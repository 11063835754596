import { Injectable, OnInit } from '@angular/core';
import { Storage } from '@ionic/Storage';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalmemService implements OnInit {
  questPicked = new Subject<boolean>();
  userEntered = new Subject<boolean>();
  questPaid = new Subject<boolean>();
  questDownloaded = new Subject<boolean>();
  questGA = new BehaviorSubject<string>('');
  questCost = 20;
  questId = '';
  theQuest: Quest;
  rights: string;

  constructor(private storage: Storage) { }

  ngOnInit() {
    // set initial values
    this.questPicked.next(false);
    this.userEntered.next(false);
    this.questPaid.next(false);
    this.questDownloaded.next(false);
    this.questGA.next('');
  }

  storeTheQuest() {
    this.storage.set( 'currquest', this.theQuest);
  }
  loadTheQuest() {
    this.storage.get( 'currquest').then(
      (qst) => {
        if (qst !== null) {
          this.theQuest = qst;
          this.questDownloaded.next(true);
          console.log('Quest Loaded');
        } else {
          alert('we could not find a stored quest');
        }
      },
      (rejectReason) => { console.log(rejectReason); }
      );
  }

  // turns read quest into one with questpoints
  setTheQuest(theQuest: QuestClues) {
    this.theQuest = {
      errors: theQuest.errors,
      startCode: theQuest.startCode,
      startUrl: theQuest.startUrl,
      questPoints: []
      };
  // stores the active quest
    theQuest.retClues.forEach((retClue: RetClue) => {
      // for each questpoint split the clue
      const questionList: Questionobj[] = [];
      const cluelist = retClue.questions.split(',');
      cluelist.forEach(clue => {
          questionList.push({sentence: clue, visible: false, points: 5 });
        });
      this.theQuest.questPoints.push({clueID: retClue.clueID, plusCode: retClue.plusCode,
        solveCode: retClue.solveCode, solveUrl: retClue.solveCode, questions: questionList});
      });
  }
}

export interface Quest {
  errors: string;
  startUrl: string;
  startCode: string;
  questPoints: QuestPoint[];
}

export interface QuestPoint {
  clueID: string;
  questions: Questionobj[];
  plusCode: string;
  solveCode: string;
  solveUrl: string;
}

export interface Questionobj {
  sentence: string;
  visible: boolean;
  points: number;
}


export interface QuestClues {
  errors: string;
  startUrl: string;
  startCode: string;
  retClues: [];
}

export interface RetClue {
  clueID: string;
  questions: string;
  plusCode: string;
  solveCode: string;
  solveUrl: string;
}
