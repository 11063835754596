import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'get-ready', loadChildren: './get-ready/get-ready.module#GetReadyPageModule' },
  { path: 'get-set', loadChildren: './get-set/get-set.module#GetSetPageModule' },
  { path: 'go', loadChildren: './go/go.module#GoPageModule' },
  { path: 'clues', loadChildren: './Quest/clues/clues.module#CluesPageModule' },
  { path: 'clue', loadChildren: './Quest/clue/clue.module#CluePageModule' },
  { path: 'user-maint', loadChildren: './user-maint/user-maint.module#UserMaintPageModule' },
  { path: 'quest-points', loadChildren: './quest-points/quest-points.module#QuestPointsPageModule' },
  { path: 'quest-builder', loadChildren: './quest-builder/quest-builder.module#QuestBuilderPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
