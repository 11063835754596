// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // googleApiKey: 'AIzaSyCGkdvOQwt1N7n31WPLkSfJRuTHly2tRcc',
  googleApiKey: 'AIzaSyCS1vNEPj7XkEtijoP4tKKk2UumiWGLcX0',
  stripeApiKey: 'pk_test_VkB2hbsnfoTRhebXYPDjRl5f009c5gXeAK',
  production: false,
  firebase: {
    apiKey: 'AIzaSyDanTgMSUwA9yWNfPTtocQis5_y3_prpP4',
    authDomain: 'quest-248310.firebaseapp.com',
    databaseURL: 'https://quest-248310.firebaseio.com',
    projectId: 'quest-248310',
    storageBucket: 'quest-248310.appspot.com',
    messagingSenderId: '99483371818',
    appId: '1:99483371818:web:0bee79cb684039af93f653'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
